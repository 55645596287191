import React from 'react'
import user1 from './../images/user1.jpeg'
import user2 from './../images/user2.jpeg'
import user3 from './../images/user3.jpeg'
import './Testimonials.css'

const Testimonials = () => {
    return (
        <div className='testimonials' id='testimonials'>
            <div className='container'>
                <h2>Research Interest</h2>
                <span className='line'></span>
                <div className='content'>
                    <div className='card'>
                        <h4>Machine Learning</h4>
                        {/* <img src={user1} alt='user1'/> */}
                        <p>      
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis
        nesciunt excepturi quos obcaecati incidunt voluptatem ipsam sunt ipsum,
        autem deleniti cupiditate molestias quis unde quae totam porro dicta
        iure animi inventore, veniam hic! Omnis nulla, delectus a voluptatibus
      </p>
                        {/* <p><span>Johnson.M.J.</span></p>
                        <p>Director of "Financial Times"</p> */}
                    </div>
                    <div className='card'>
                        {/* <img src={user2} alt='user1'/> */}
                        <h4>Blockchain </h4>
                        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis
        nesciunt excepturi quos obcaecati incidunt voluptatem ipsam sunt ipsum,
        autem deleniti cupiditate molestias quis unde quae totam porro dicta
        iure animi inventore, veniam hic! Omnis nulla, delectus a voluptatibus
      </p>
                        {/* <p><span>Carol Harper</span></p>
                        <p>Director at Risktec Solutions Ltd</p> */}
                    </div>
                    <div className='card'>
                        {/* <img src={user3} alt='user1'/> */}
                        <h4>Quantum Computer</h4>
                        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis
        nesciunt excepturi quos obcaecati incidunt voluptatem ipsam sunt ipsum,
        autem deleniti cupiditate molestias quis unde quae totam porro dicta
        iure animi inventore, veniam hic! Omnis nulla, delectus a voluptatibus
      </p>
                        {/* <p><span>Snow.J.R.</span></p>
                        <p>Managing Director of BPW Global</p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials
