import React from 'react'
import './Hero.css'

const Hero = () => {
    return (
        <div className='hero'>
            <div className='content'>
                <p></p>
                <p></p>
                <p>Software Engineer</p>
                <p>“Sometimes problems don’t require a solution to solve them; Instead they require maturity to outgrow them.”</p>
                <p>– Steve Maraboli</p>
                <button href='/' className='button'>Experience matters</button>
            </div>
        </div>
    )
}

export default Hero
