import React from 'react'
import john from './../images/john-doe.jpg'
import './About.css'

const About = () => {
    return (
        <div className='about' id='about'>
            <div className='container'>
                <img src={john} alt='john' />
                <div className='col-2'>
                    <h2>About</h2>
                    <span className='line'></span>
                    <p>I'm a software engineer who lives in Portland, OR with a passion for computer science, Machine learning, and Web development.

I earned a Bachelor's degree in Software Engineer and Applied Math at Oregon Tech and then a Master's degree in Computer Science from the University of Illinois, Springfield. While attending Oregon Tech, I began my software engineering career. Software engineering enormously rewarding field and I am excited to be a part of it.</p>
<p>
I love to learn new stuff, and Software Engineer gives me that opportunity; as it's continuously evolving, its possibilities are endless.

</p>
<p>
     I recently worked on web development using .Net Framework and JavaScript & it's libraries/frameworks. My primary professional languages have been C/C++ C#, Java, SQL, C++, HTML, JavaScript, and python. I don't define myself by the programming language.
</p>

<p>
I have also spent time disassembled laptop computers down to the motherboard and reassembled them back together. Building clusters, playing with Arduino and raspberry pi(IoT).
</p>
 
                    {/* <p>I am John Doe, a senior software engineer.</p>
                    <button className='button'>Explore More</button> */}
                </div>
            </div>
        </div>
    )
}

export default About
